<template>
  <section>
    <div class="content-header">
      <h2>Embedding resilience in the career process</h2>
    </div>
    <div class="content-wrapper">
      <p>The EAB (formerly the Education Advisory Board) provides data-driven insights and best-in-class capabilities in five major areas: enrollment; student success; data and analytics; institutional strategy; and diversity, equity and inclusion.</p>
      <p>The <a href="https://eab.com/research/student-affairs/case-study/embedding-resilience-into-the-career-process/" target="_blank">Embedding Resilience into the Career Process</a>
 report provides information that can help educators to:</p>
      <ul>
        <li>Identify opportunities to infuse resilience skills into the career process </li>
        <li>Review how to support campus partners who encounter students struggling with career-related setbacks </li>
        <li>Brainstorm services or resources that can be enhanced by the addition of resilience content </li>
      </ul>
    </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '04',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
